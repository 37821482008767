import { CircularProgress, Tab, Tabs } from '@mui/material';
// eslint-disable-next-line import/named
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectReseller } from '../../common/store/reseller';
import { gridColumnWidths } from '../../common/utils/grid-column-widths';
import { EditTemplate } from './EditTemplate';
import {
  useGetEmailTemplateTypesQuery,
  useGetEmailTemplatesQuery
} from './email-templates-api-slice';
import { TemplateType } from './email-templates-types';

export const EmailTemplates = () => {
  const reseller = useSelector(selectReseller);
  const { data, isLoading: isDataLoading } = useGetEmailTemplatesQuery({
    reseller
  });
  const { data: templateTypes, isLoading: isTypesLoading } =
    useGetEmailTemplateTypesQuery({ reseller });

  const getTemplateTypeName = (templateTypeId: number) => {
    const name = templateTypes.find(
      (type: TemplateType) => type.id === templateTypeId
    )?.name;
    return name;
  };
  const getTemplateType = (templateTypeId: number) => {
    const type = templateTypes.find(
      (type: TemplateType) => type.id === templateTypeId
    );
    return type;
  };

  const columns = [
    {
      ...gridColumnWidths.domainName,
      field: 'emailTemplateType',
      filterable: false,
      headerName: 'Template Type',
      renderCell: (params: GridRenderCellParams) =>
        getTemplateTypeName(params.row.emailTemplateType),
      sortable: false
    },
    {
      ...gridColumnWidths.domainName,
      field: 'subject',
      filterable: false,
      headerName: 'Subject',
      sortable: false
    },
    {
      field: 'system',
      filterable: false,
      ...gridColumnWidths.tld,
      headerName: 'Template Style',
      renderCell: (params: GridRenderCellParams) =>
        params.row.system ? 'Default' : 'Customized',
      sortable: false
    },

    {
      field: 'edit',
      ...gridColumnWidths.icon,
      filterable: false,
      headerName: 'Edit',
      renderCell: (params: GridRenderCellParams) => (
        <EditTemplate
          reseller={reseller}
          templateData={params.row}
          templateType={getTemplateType(params.row.emailTemplateType)}
        />
      ),
      sortable: false
    }
  ];

  return isDataLoading || isTypesLoading ? (
    <CircularProgress />
  ) : (
    <>
      <Tabs sx={{ pl: 3 }} value={0}>
        <Tab label="Email templates" />
      </Tabs>
      <DataGridPro
        columns={columns}
        disableColumnFilter
        disableRowSelectionOnClick={true}
        disableVirtualization={import.meta.env.MODE === 'test'}
        getRowId={row => row.emailTemplateType}
        loading={isTypesLoading || isDataLoading}
        rows={data ?? []}
        sx={{ backgroundColor: 'white', height: 'calc(100% - 48px)' }}
      />
    </>
  );
};
