import { muiTheme } from '@appdetex/detect-mui';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import '../../node_modules/@appdetex/detect-mui/dist/style.css';
import { BaseRoutes } from '../common/components/BaseRoutes';
import { config } from '../common/config/config';
import '../common/initializers/index';
import { setupStore } from '../common/store/store';
import { CookieToast } from '../features/cookie-policy/CookieToast';
import favicon from '../images/favicon.ico';
import './App.css';

const store = setupStore();

export const App = () => {
  document.title = config.APP_TITLE;
  if (config.HAS_FAVICON) {
    const faviconElement = document.getElementById('favicon');
    faviconElement?.setAttribute('href', favicon);
  }

  const theme = createTheme({
    ...muiTheme,
    ...{
      components: {
        MuiTab: {
          styleOverrides: ({ theme }) => ({
            root: {
              '&.Mui-selected': {
                color: theme.primary.main
              },
              color: theme.secondary.main,
              fontWeight: 700
            }
          })
        }
      }
    }
  });

  // TODO i think both of these should be changed in detect-mui
  theme.palette.secondary.main = '#555';
  theme.typography.button.textTransform = 'none';

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={4000}
            domRoot={document.body}
            style={{ maxWidth: 450 }}
          >
            <CookieToast />
            <BrowserRouter>
              <BaseRoutes />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
};
