import { EMAIL_TEMPLATES_TAG, apiSlice } from '../../common/store/api-slice';

export const emailTemplatesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmailTemplateTypes: builder.query({
      providesTags: [EMAIL_TEMPLATES_TAG],
      query: ({ reseller }) => `resellers/${reseller}/email-template-types`,
      transformResponse: response => response.entity
    }),
    getEmailTemplates: builder.query({
      providesTags: [EMAIL_TEMPLATES_TAG],
      query: ({ reseller }) =>
        `resellers/${reseller}/email-templates?maxResults=10000&offset=0`,
      transformResponse: response => response.entries
    }),
    updateEmailTemplate: builder.mutation({
      invalidatesTags: [EMAIL_TEMPLATES_TAG],
      query: ({ emailTemplate, reseller }) => ({
        body: emailTemplate,
        method: 'PUT',
        url: `resellers/${reseller}/email-templates/${emailTemplate.emailTemplateType}`
      })
    })
  })
});

export const {
  useGetEmailTemplateTypesQuery,
  useGetEmailTemplatesQuery,
  useUpdateEmailTemplateMutation
} = emailTemplatesApiSlice;
