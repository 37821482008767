export const getSettingsMenuOptions = roles =>
  [
    {
      checkRoles: roles => roles.isAdmin && !roles.isSystemAdmin,
      key: 'customers',
      legacyPath: 'customers-manage',
      title: 'Customers'
    },
    {
      checkRoles: roles =>
        roles.isSuperAdmin || roles.isCustomerAdmin || roles.isSystemAdmin,
      key: 'users',
      path: '/users/customer',
      title: 'Users'
    },
    {
      checkRoles: roles => roles.isAdmin && !roles.isSystemAdmin,
      key: 'email-templates',
      path: 'email-templates',
      title: 'Email Templates'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'manual-orders',
      legacyPath: 'manual-orders',
      title: 'Manual Orders'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'service-items',
      legacyPath: 'service-items',
      title: 'Service Items'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'global-contacts',
      legacyPath: 'global-contacts',
      title: 'Global Contacts'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'tld-health',
      path: 'tld-health',
      title: 'TLD Health'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'system-search-groups',
      path: 'system-search-groups',
      title: 'System Search Groups'
    },
    {
      checkRoles: roles => roles.isSuperAdmin,
      key: 'sign-off-settings',
      path: 'sign-off-settings',
      title: 'Sign-Off Settings'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'organization-details',
      legacyPath: 'mycustomer/organization-details',
      title: 'Organization Details'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'account-manager',
      legacyPath: 'mycustomer/account-manager',
      title: 'Account Manager'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'contact-sets',
      legacyPath: 'mycustomer/default-contacts',
      title: 'Contact Sets'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'domain-labels',
      legacyPath: 'mycustomer/domain-labels',
      title: 'Domain Labels'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'notification-preferences',
      legacyPath: 'mycustomer/notifications',
      title: 'Notification Preferences'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'divider1',
      title: 'DIVIDER'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'search-groups',
      legacyPath: 'search-groups',
      title: 'Search Groups'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'name-server-groups',
      legacyPath: 'name-server-groups',
      title: 'Name Server Groups'
    },
    {
      checkRoles: roles => !roles.isAdmin,
      key: 'smd-files',
      legacyPath: 'smd-files',
      title: 'Signed Mark Data Files'
    },
    {
      checkRoles: roles => roles.isCustomer && !roles.isCustomerAdmin,
      key: 'users',
      path: '/users/customer',
      title: 'Users'
    },
    {
      checkRoles: roles => !roles.isAdmin && roles.isCustomerAdmin,
      key: 'restricted-users',
      legacyPath: 'restricted-users-manage',
      title: 'Restricted Users'
    }
  ].filter(option => option.checkRoles(roles));
