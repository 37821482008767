import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import { App } from './app/App';
import { ErrorBoundaryFallback } from './common/components/error-boundary-fallback/ErrorBoundaryFallback';

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
