import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { selectActiveCustomer } from '../../common/store/customers-slice';
import { useGetCustomerForDomainNameQuery } from '../../common/store/domains-api-slice';
import { selectReseller } from '../../common/store/reseller';
import { selectIsSuperAdminOrFinance } from '../auth/auth-slice';
import { useRespondToOutboundTransferEmailMutation } from './outbound-transfer-api-slice';

export const OutboundTransfer = () => {
  const { domainName, option, uniqueId } = useParams();

  const navigate = useNavigate();

  const isSuperAdminOrFinance = useSelector(selectIsSuperAdminOrFinance);
  const reseller = useSelector(selectReseller);
  const activeCustomer = useSelector(selectActiveCustomer);

  const [
    respondToOutboundTransferRequest,
    {
      isError: isSubmitError,
      isLoading: isSubmitLoading,
      isSuccess: isSubmitSuccess
    }
  ] = useRespondToOutboundTransferEmailMutation();

  const {
    data: customerResponse,
    isError: isCustomerError,
    isLoading: isCustomerLoading,
    isUninitialized: isCustomerUninitialized
  } = useGetCustomerForDomainNameQuery(
    {
      domainName,
      reseller
    },
    {
      skip: !domainName || !reseller || !isSuperAdminOrFinance
    }
  );

  useEffect(() => {
    if (isSubmitSuccess) {
      enqueueSnackbar(
        `Successfully ${option === 'approve' ? 'approved' : 'rejected'} the outbound transfer for ${domainName}`,
        {
          variant: 'success'
        }
      );

      navigate('/dashboard');
    } else if (isSubmitError) {
      enqueueSnackbar(
        `Failed to ${option} the outbound transfer for ${domainName}. Please try again later or contact support.`,
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccess, isSubmitError]);

  useEffect(() => {
    if (isCustomerError) {
      enqueueSnackbar(
        `Failed to retrieve a valid customer for the domain ${domainName}. Please try again later or contact support.`,
        {
          variant: 'error'
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerError]);

  const submitResponse = (customerToRespondWith: string) => {
    respondToOutboundTransferRequest({
      accountName: customerToRespondWith,
      domainName: domainName,
      option: option,
      reseller: reseller,
      uniqueId: uniqueId
    });
  };

  const handleClose = (shouldSubmit: boolean) => {
    if (shouldSubmit) {
      if (isSuperAdminOrFinance) {
        // use the customer the domain belongs to
        submitResponse(customerResponse.accountName);
      } else {
        // use the active customer
        submitResponse(activeCustomer.accountName);
      }
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Outbound Transfer: {domainName}</DialogTitle>
      <DialogContent>
        Are you sure you want to {option} the outbound transfer for {domainName}
        ?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} variant="outlined">
          No
        </Button>
        <LoadingButton
          disabled={isCustomerError}
          loading={
            (isSuperAdminOrFinance &&
              (isCustomerLoading || isCustomerUninitialized)) ||
            isSubmitLoading
          }
          onClick={() => handleClose(true)}
          variant="contained"
        >
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
