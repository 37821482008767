import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  TextField
} from '@mui/material';
import { bindTrigger } from 'material-ui-popup-state';
import {
  bindDialog,
  bindToggle,
  usePopupState
} from 'material-ui-popup-state/hooks';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer } from 'react';

import { emailTemplateSchema } from './email-template-schema';
import { useUpdateEmailTemplateMutation } from './email-templates-api-slice';
import {
  emailTemplateReducer,
  getInitialEmailTemplateState
} from './email-templates-reducer';
import {
  EmailTemplate,
  EmailTemplateState,
  TemplateType
} from './email-templates-types';

type Props = {
  reseller: string;
  templateData: EmailTemplate;
  templateType: TemplateType;
};

export const EditTemplate: React.FC<Props> = ({
  reseller,
  templateData,
  templateType
}) => {
  const dialogState = usePopupState({
    popupId: 'edit-template-dialog',
    variant: 'dialog'
  });

  const { enqueueSnackbar } = useSnackbar();

  const [updateTemplate, { isError, isLoading, isSuccess }] =
    useUpdateEmailTemplateMutation();

  const getUpdateBody = () => ({
    bccEmail: formData.bccEmail.value,
    ccEmail: formData.ccEmail.value,
    description: templateType.description,
    emailTemplateType: templateData.emailTemplateType,
    name: templateType.name,
    subject: formData.subject.value,
    system: templateData.system,
    template: formData.template.value
  });

  const handleRequiredValidation = ({
    path
  }: {
    path: keyof EmailTemplateState;
  }) =>
    formDataDispatch({
      errorMessage: 'This field is required',
      path,
      type: 'SET_ERROR'
    });

  const handleEmailValidation = ({
    path
  }: {
    path: keyof EmailTemplateState;
  }) =>
    formDataDispatch({
      errorMessage: 'Invalid email address',
      path,
      type: 'SET_ERROR'
    });

  const [formData, formDataDispatch] = useReducer(
    emailTemplateReducer,
    getInitialEmailTemplateState(templateData)
  );

  const handleChange = (
    event: React.FormEvent<HTMLElement>,
    path: keyof EmailTemplateState
  ) => {
    const value = (event.target as HTMLInputElement).value;
    formDataDispatch({ path, type: 'UPDATE', value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    emailTemplateSchema(handleRequiredValidation, handleEmailValidation)
      .validate(formData)
      .then(() => {
        updateTemplate({ emailTemplate: getUpdateBody(), reseller });
      })
      .catch(error => {
        window.console.error(error);
      });
  };

  useEffect(() => {
    if (!dialogState.isOpen) {
      formDataDispatch({
        payload: getInitialEmailTemplateState(templateData),
        type: 'RESET'
      });
    }
  }, [dialogState.isOpen, templateData]);

  useEffect(() => {
    if (isSuccess) {
      dialogState.close();
      enqueueSnackbar('Email template successfully updated.', {
        variant: 'success'
      });
    } else if (isError) {
      enqueueSnackbar('An error occurred. Please try again.', {
        variant: 'error'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  return (
    <>
      <IconButton
        {...bindTrigger(dialogState)}
        aria-label="Edit template"
        size="small"
      >
        <EditIcon />
      </IconButton>
      <Dialog
        {...bindDialog(dialogState)}
        aria-label="Details Popup"
        fullWidth
        maxWidth="md"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3}>
              <TextField
                disabled
                label="Template Type"
                value={templateType.name}
              />
              <TextField
                error={formData.ccEmail.hasError}
                helperText={formData.ccEmail.errorMessage}
                label="CC Email"
                onChange={event => handleChange(event, 'ccEmail')}
                value={formData.ccEmail.value || ''}
              />
              <TextField
                error={formData.bccEmail.hasError}
                helperText={formData.bccEmail.errorMessage}
                label="BCC Email"
                onChange={event => handleChange(event, 'bccEmail')}
                value={formData.bccEmail.value || ''}
              />
              <TextField
                error={formData.subject.hasError}
                helperText={formData.subject.errorMessage}
                label="Subject"
                onChange={event => handleChange(event, 'subject')}
                value={formData.subject.value || ''}
              />
              <TextField
                error={formData.template.hasError}
                helperText={formData.template.errorMessage}
                label="Template"
                minRows={3}
                multiline
                onChange={event => handleChange(event, 'template')}
                value={formData.template.value || ''}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" {...bindToggle(dialogState)}>
              Cancel
            </Button>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
