import { NAME_SERVER_GROUPS_TAG, apiSlice } from '../../common/store/api-slice';

const nameServerApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    deleteNameServerGroup: builder.mutation({
      invalidatesTags: [NAME_SERVER_GROUPS_TAG],
      query: ({ customerName, nameServerGroupId, reseller }) => ({
        method: 'DELETE',
        url: `resellers/${reseller}/customers/${customerName}/name-server-groups/${nameServerGroupId}`
      })
    }),
    editNameServerGroup: builder.mutation({
      query: ({ customerName, nameServerGroup, reseller }) => ({
        body: nameServerGroup,
        method: 'PUT',
        url: `resellers/${reseller}/customers/${customerName}/name-server-groups/${nameServerGroup.id}`
      })
    }),
    getNameServerGroups: builder.query({
      providesTags: [NAME_SERVER_GROUPS_TAG],
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/name-server-groups`,
      transformResponse: response => {
        return response.entity.map(nameServerGroup => ({
          ...nameServerGroup,
          label: nameServerGroup.name
        }));
      }
    }),
    saveNewNameServerGroup: builder.mutation({
      invalidatesTags: [NAME_SERVER_GROUPS_TAG],
      query: ({ customerName, nameServerGroup, reseller }) => ({
        body: nameServerGroup,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/name-server-groups`
      })
    }),
    verifyNameServerGroupForTld: builder.query({
      query: ({ customerName, nameServerGroupId, reseller, tldName }) =>
        `resellers/${reseller}/customers/${customerName}/name-server-groups/${tldName}/${nameServerGroupId}`,
      transformResponse: response => {
        if (!response.valid) {
          throw response.reason;
        } else {
          return response;
        }
      }
    }),
    verifyNameServerGroupsForTlds: builder.query({
      query: ({ customerName, reseller, tldsAndNameServerGroupIds }) => ({
        body: tldsAndNameServerGroupIds,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/name-server-groups/validate`
      }),
      transformResponse: ({ entity }) => ({
        invalidTlds: entity
          .filter(tld => tld.valid === false)
          .map(invalidTld => invalidTld.tld),
        tlds: entity
      })
    })
  })
});

export const {
  useDeleteNameServerGroupMutation,
  useEditNameServerGroupMutation,
  useGetNameServerGroupsQuery,
  useSaveNewNameServerGroupMutation,
  useVerifyNameServerGroupForTldQuery,
  useVerifyNameServerGroupsForTldsQuery
} = nameServerApiSlice;
