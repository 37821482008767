import { boolean, object, string } from 'yup';

/**
 *
 * @param {Function} handleRequiredValidation
 * @returns
 */
const defaultFieldSchema = handleRequiredValidation =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string().required(handleRequiredValidation).default('')
  });

/**
 *
 * @param {Function} handleEmailValidation
 * @returns
 */
const emailFieldSchema = handleEmailValidation =>
  object({
    errorMessage: string().default(''),
    hasError: boolean().default(false),
    touched: boolean().default(false),
    value: string().email(handleEmailValidation).default('')
  });

/**
 *
 * @param {Function} handleRequiredValidation
 * @param {Function} handleEmailValidation
 * @returns
 */
export const emailTemplateSchema = (
  handleRequiredValidation,
  handleEmailValidation
) =>
  object({
    bccEmail: emailFieldSchema(handleEmailValidation),
    ccEmail: emailFieldSchema(handleEmailValidation),
    subject: defaultFieldSchema(handleRequiredValidation),
    template: defaultFieldSchema(handleRequiredValidation)
  });
