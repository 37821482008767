import { apiSlice } from '../../common/store/api-slice';

const outboundTransferApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    respondToOutboundTransferEmail: builder.mutation({
      query: ({ accountName, domainName, option, reseller, uniqueId }) => ({
        method: 'POST',
        url: `resellers/${reseller}/customers/${accountName}/transfer-authorization/outbound/${option === 'approve' ? 'accept' : 'reject'}?domain=${domainName}&id=${uniqueId}`
      })
    })
  })
});

export const { useRespondToOutboundTransferEmailMutation } =
  outboundTransferApiSlice;
