import {
  EmailTemplate,
  EmailTemplateAction,
  EmailTemplateState
} from './email-templates-types';

export const getInitialEmailTemplateState = (templateData: EmailTemplate) => {
  const { bccEmail, ccEmail, subject, template } = templateData;
  return {
    bccEmail: {
      errorMessage: '',
      hasError: false,
      touched: false,
      value: bccEmail
    },
    ccEmail: {
      errorMessage: '',
      hasError: false,
      touched: false,
      value: ccEmail
    },
    subject: {
      errorMessage: '',
      hasError: false,
      touched: false,
      value: subject
    },
    template: {
      errorMessage: '',
      hasError: false,
      touched: false,
      value: template
    }
  };
};

export const emailTemplateReducer = (
  state: EmailTemplateState,
  action: EmailTemplateAction
) => {
  switch (action.type) {
    case 'UPDATE':
      if (action.path) {
        action.path = action.path.split('.')[0] as keyof EmailTemplateState;
      }
      state[action.path].value = action.value;
      state[action.path].touched = true;
      state[action.path].errorMessage = '';
      state[action.path].hasError = false;
      break;
    case 'SET_ERROR':
      if (action.path) {
        action.path = action.path.split('.')[0] as keyof EmailTemplateState;
      }
      state[action.path].errorMessage = action.errorMessage;
      state[action.path].hasError = true;
      break;
    case 'RESET':
      return action.payload;
    default:
      break;
  }

  return { ...state };
};
