import dayjs from 'dayjs';
import { has } from 'lodash-es';

import {
  CUSTOMER_TAG,
  DNSSEC_TAG,
  HOST_NAME_SERVER_TAG,
  apiSlice
} from '../../common/store/api-slice';
import { getAssociatedLabelsForDomain } from '../../common/store/api-slice-utils';
import { getLocalizedDate, getLocalizedDateTime } from '../utils/date-time';
import { generateId } from '../utils/generate-id';
import { getTld } from '../utils/get-tld';

const DEFAULT_FROM_DATE = dayjs('1980-07-31 00:00').format('YYYY-MM-DD');
const getDefaultToDate = () => dayjs().format('YYYY-MM-DD');
const DEFAULT_SORT = 'fullDomainName';

const getRegistrarLocks = domain => {
  const updateLockSupportAvailable = Boolean(domain.$tld?.updateLockSupport);
  const transferLockSupportAvailable = Boolean(
    domain.$tld?.transferLockSupport
  );
  const deleteLockSupportAvailable = Boolean(domain.$tld?.deleteLockSupport);

  const registrarLocks = {
    types: [
      {
        available: updateLockSupportAvailable,
        enabled:
          updateLockSupportAvailable &&
          domain.domainStatuses.includes('clientUpdateProhibited'),
        name: 'update'
      },
      {
        available: transferLockSupportAvailable,
        enabled:
          transferLockSupportAvailable &&
          domain.domainStatuses.includes('clientTransferProhibited'),
        name: 'transfer'
      },
      {
        available: deleteLockSupportAvailable,
        enabled:
          deleteLockSupportAvailable &&
          domain.domainStatuses.includes('clientDeleteProhibited'),
        name: 'delete'
      }
    ]
  };

  registrarLocks.anyAvailable = registrarLocks.types.some(
    lock => lock.available === true
  );
  registrarLocks.noneAvailable = registrarLocks.types.every(
    lock => lock.available === false
  );

  if (registrarLocks.anyAvailable) {
    registrarLocks.allEnabled = registrarLocks.types.every(
      lock => lock.enabled === true
    );
    registrarLocks.someEnabled =
      registrarLocks.types.some(lock => lock.enabled === true) &&
      !registrarLocks.allEnabled;
    registrarLocks.noneEnabled = registrarLocks.types.every(
      lock => lock.enabled === false
    );
  }

  return registrarLocks;
};

const getMaxAutoRenew = (entry, appInfoData) =>
  appInfoData.maxAutorenewEnabled && entry.maxAutorenew;

const getPathForDomainSyncing = ({ customerName, reseller }) => {
  const basePath = `/resellers/${reseller}/`;

  return customerName
    ? `${basePath}customers/${customerName}/domains/sync`
    : `${basePath}domains/sync`;
};

export const domainsApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    exportCustomerDomains: builder.mutation({
      query: ({
        automationType,
        customerName,
        dir,
        find,
        fromDate = DEFAULT_FROM_DATE,
        labelIds = [],
        nameServers = [],
        reseller,
        sort = DEFAULT_SORT,
        toDate = getDefaultToDate()
      }) => ({
        body: {
          automationType,
          dir,
          find,
          fromDate,
          labelIds,
          nameServers,
          sort,
          toDate
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/domains/export`
      })
    }),
    getBulkDomainTransferAvailability: builder.query({
      query: ({ customerName, domainNames, reseller }) => ({
        body: domainNames,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/domains/transfer-availability`
      })
    }),
    getCustomerDomain: builder.query({
      providesTags: [HOST_NAME_SERVER_TAG],
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/${domainName}`,
      transformResponse: response => {
        if (response) {
          response.$hostNameServers = response.customerDomainHosts?.map(
            domainHost => ({
              ...domainHost,
              id: generateId(),
              isNewRecord: false
            })
          );
        }
        return response;
      }
    }),
    getCustomerDomains: builder.query({
      providesTags: (result, error, { customerName }) => [
        { id: customerName, type: CUSTOMER_TAG }
      ],
      query: ({
        // eslint-disable-next-line no-unused-vars
        appInfoData,
        // eslint-disable-next-line no-unused-vars
        associationsData,
        automationType,
        customerName,
        dir,
        find,
        fromDate = DEFAULT_FROM_DATE,
        labelIds = [],
        // eslint-disable-next-line no-unused-vars
        labelsData,
        maxResults,
        nameServers = [],
        offset = 0,
        reseller,
        sort = DEFAULT_SORT,
        // eslint-disable-next-line no-unused-vars
        tldsData,
        toDate = getDefaultToDate()
      }) => ({
        body: {
          automationType,
          dir,
          find,
          fromDate,
          labelIds,
          maxResults,
          nameServers,
          offset,
          sort,
          toDate
        },
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/domains`
      }),
      transformResponse: (
        response,
        meta,
        { appInfoData, associationsData, labelsData, tldsData }
      ) => {
        response.entries = response.entries.map(entry => {
          entry.$expirationDateFormatted = getLocalizedDate(
            entry.expirationDate
          );

          if (entry.renewalDate) {
            entry.$renewalDateFormatted = getLocalizedDate(entry.renewalDate);
          } else {
            entry.$renewalDateFormatted = null;
          }

          entry.$localPresenceExpirationDateFormatted =
            entry.localPresenceExpirationDate
              ? getLocalizedDate(entry.localPresenceExpirationDate)
              : null;
          entry.$registrarCreationDateFormatted = getLocalizedDate(
            entry.registrarCreationDate
          );
          entry.$nameServers = entry.nameServerInfos.sort();
          entry.$nameServerInfosFormatted = entry.$nameServers.join(', ');

          // Remove after https://tracer-ai.atlassian.net/browse/REG-2928 is deployed
          if (has(entry, 'renewalAvailability.years')) {
            entry.renewalAvailability.$years =
              entry.renewalAvailability.years.sort();
          }

          if (labelsData && associationsData) {
            entry.$labels = getAssociatedLabelsForDomain(
              entry.domainName,
              labelsData,
              associationsData
            );
          }

          if (tldsData) {
            entry.$tld = getTld(entry.domainName, tldsData);
          }

          entry.$registrarLocks = getRegistrarLocks(entry);

          entry.$deleteLockEnabled = entry.$registrarLocks.types.find(
            lock => lock.name === 'delete' && lock.enabled
          );

          if (appInfoData) {
            entry.$isMaxAutoRenew = getMaxAutoRenew(entry, appInfoData);
          }

          entry.$id = entry.id;

          return entry;
        });

        return response;
      }
    }),
    getCustomerForDomainName: builder.query({
      query: ({ domainName, reseller }) => ({
        method: 'GET',
        url: `resellers/${reseller}/${domainName}`
      })
    }),
    getCustomerNameServers: builder.query({
      query: ({ customerName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/ns-filters`,
      transformResponse: ({ groups }) =>
        groups
          .map(group =>
            group.values.map(({ name, value }) => ({
              groupName: group.name,
              // For <Autocomplete />
              id: name,
              label: name,
              name,
              value
            }))
          )
          .flat()
    }),
    getDnssecRecords: builder.query({
      providesTags: [DNSSEC_TAG],
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/${domainName}/dnssec`,
      transformResponse: response =>
        response.map(({ algorithmType, digest, digestType, id, keyTag }) => ({
          algorithmType,
          digest,
          digestType,
          id,
          isNewDnsRecord: false,
          keyTag
        }))
    }),
    getDomainActionHistory: builder.query({
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/${domainName}/history?maxResults=10000&offset=0`,
      transformResponse: ({ entries }) =>
        entries.map(entry => ({
          ...entry,
          $createdFormatted: getLocalizedDate(entry.created),
          $expirationDateFormatted: getLocalizedDate(entry.expirationDate),
          $historyActionDateFormatted: getLocalizedDateTime(
            entry.historyActionDate
          )
        }))
    }),
    // any domain that's currently in a pending transfer or any domain that's already in the customer's domain
    // portfolio should return a 409 response. The former should return a body of {"type": "transfer"} and the
    // latter should return {"type": "domain"}. The exception is a .be domain already in the portfolio. Since
    // .be contact changes have to happen via transfer commands at the registry, a .be domain will return 200
    // if it already exists in the domain portfolio, but 409 if there's already a pending transfer for that
    // .be domain.
    getDomainTransferAvailability: builder.query({
      // eslint-disable-next-line no-unused-vars
      query: ({ customerName, domainName, reseller }) =>
        `resellers/${reseller}/customers/${customerName}/domains/${domainName}/transfer-availability`,
      transformErrorResponse: (response, meta, { domainName }) => {
        const getErrorMessage = (domainName, errorType) => {
          if (errorType === 'transfer') {
            return `${domainName} is pending transfer and cannot be transferred again.`;
          } else if (errorType === 'domain') {
            return `${domainName} is already in your domain portfolio.`;
          }
        };

        if (response.status === 409) {
          response = {
            availableForTransfer: false,
            domainName,
            errorMessage: getErrorMessage(domainName, response.data.type)
          };
        }
        return response;
      },
      transformResponse: (response, meta, { domainName }) => ({
        availableForTransfer: true,
        domainName
      })
    }),
    importDomain: builder.mutation({
      query: ({ body, customerName, domainName, reseller }) => ({
        body,
        method: 'POST',
        url: `resellers/${reseller}/customers/${customerName}/domains/${domainName}/import`
      })
    }),
    sendAuthCode: builder.mutation({
      query: ({ customerName, domainName, reseller }) => ({
        method: 'POST',
        url: `/resellers/${reseller}/customers/${customerName}/domains/${domainName}/send_auth_code_via_email`
      })
    }),
    syncDomains: builder.mutation({
      invalidatesTags: [CUSTOMER_TAG],
      query: ({ body, customerName, reseller }) => ({
        body,
        method: 'POST',
        url: getPathForDomainSyncing({ customerName, reseller })
      })
    }),
    updateDomains: builder.mutation({
      invalidatesTags: [CUSTOMER_TAG],
      query: ({ body, customerName, reseller }) => ({
        body,
        method: 'PUT',
        url: `resellers/${reseller}/customers/${customerName}/domains`
      })
    })
  })
});

export const {
  useExportCustomerDomainsMutation,
  useGetBulkDomainTransferAvailabilityQuery,
  useGetCustomerDomainQuery,
  useGetCustomerDomainsQuery,
  useGetCustomerForDomainNameQuery,
  useGetCustomerNameServersQuery,
  useGetDnssecRecordsQuery,
  useGetDomainActionHistoryQuery,
  useGetDomainTransferAvailabilityQuery,
  useImportDomainMutation,
  useSendAuthCodeMutation,
  useSyncDomainsMutation,
  useUpdateDomainsMutation
} = domainsApiSlice;
